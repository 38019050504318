<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="유관기관"
        :columns="grid.columns"
        rowKey="relatedOrganizationsId"
        selection="multiple"
        :data="grid.data"
        :gridHeight="componentHeight"
        :hideBottom="true"
        :isFullScreen="false"
        :filtering="false"
        :editable="editable"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="add" />
            <!-- 저장 -->
            <c-btn
              v-show="editable && grid.data.length > 0"
              :url="insertUrl"
              :isSubmit="isSave"
              :param="grid.data"
              mappingType="POST"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveItem"
              @btnCallback="saveCallback" />
            <c-btn v-if="editable && grid.data.length > 0" label="LBLREMOVE" :showLoading="true" :editable="editable" icon="remove" @btnClicked="remove" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import transactionConfig from '@/js/transactionConfig';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'related-organizations',
  props: {
    componentHeight: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            required: true,
            name: 'relatedOrganizationsName',
            field: 'relatedOrganizationsName',
            label: '유관기관',
            align: 'left',
            sortable: true,
            style: 'width:250px',
            type: 'text',
          },
          {
            required: true,
            name: 'relatedOrganizationsHp',
            field: 'relatedOrganizationsHp',
            label: '전화번호',
            align: 'left',
            sortable: true,
            style: 'width:140px',
            type: 'text',
          },
        ],
        data: [],
      },
      editable: true,
      insertUrl: '',
      searchUrl: '',
      isSave: false,
      searchParam: {}
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.eap.organization.list.url;
      this.insertUrl = transactionConfig.sop.eap.organization.insert.url;
      this.deleteUrl = transactionConfig.sop.eap.organization.delete.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    add() {
      this.grid.data.splice(0, 0, {
        relatedOrganizationsId: uid(),
        relatedOrganizationsName: '',
        relatedOrganizationsHp: '',
        useFlag: 'Y',
        editFlag: 'C',
      })
    },
    saveItem() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.saveCallback();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    preview(row) {
      this.mapSrc = row.src
    },
  }
};
</script>
