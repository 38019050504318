var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "c-table",
          {
            ref: "table",
            attrs: {
              title: "유관기관",
              columns: _vm.grid.columns,
              rowKey: "relatedOrganizationsId",
              selection: "multiple",
              data: _vm.grid.data,
              gridHeight: _vm.componentHeight,
              hideBottom: true,
              isFullScreen: false,
              filtering: false,
              editable: _vm.editable,
            },
          },
          [
            _c(
              "template",
              { slot: "table-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _vm.editable
                      ? _c("c-btn", {
                          attrs: { label: "LBLADD", icon: "add" },
                          on: { btnClicked: _vm.add },
                        })
                      : _vm._e(),
                    _c("c-btn", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.editable && _vm.grid.data.length > 0,
                          expression: "editable && grid.data.length > 0",
                        },
                      ],
                      attrs: {
                        url: _vm.insertUrl,
                        isSubmit: _vm.isSave,
                        param: _vm.grid.data,
                        mappingType: "POST",
                        label: "LBLSAVE",
                        icon: "save",
                      },
                      on: {
                        beforeAction: _vm.saveItem,
                        btnCallback: _vm.saveCallback,
                      },
                    }),
                    _vm.editable && _vm.grid.data.length > 0
                      ? _c("c-btn", {
                          attrs: {
                            label: "LBLREMOVE",
                            showLoading: true,
                            editable: _vm.editable,
                            icon: "remove",
                          },
                          on: { btnClicked: _vm.remove },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }